/*.Main :global .background1 {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.Main :global .background2 {
  width: 100%;
  position: absolute;
  margin-top: 56%;
}*/
.Main :global .TrailerSection{
  display:block;
  
}