@font-face {
  font-family: "Louis George Cafe";
  src: url("./assets/Font/Louis George Cafe.ttf") format("truetype");
}

@font-face {
  font-family: "Louis George Cafe Italic";
  src: url("./assets/Font/Louis George Cafe Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Louis George Cafe Bold";
  src: url("./assets/Font/Louis George Cafe Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Louis George Cafe Bold Italic";
  src: url("./assets/Font/Louis George Cafe Bold Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Louis George Cafe Light";
  src: url("./assets/Font/Louis George Cafe Light.ttf") format("truetype");
}

@font-face {
  font-family: "Louis George Cafe Light Italic";
  src: url("./assets/Font/Louis George Cafe Light Italic.ttf")
    format("truetype");
}
