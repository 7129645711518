/*body {
  background-image: url("./assets/Images/background/background.png");
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100%;
}*/

.App {
  font-family: "Louis George Cafe";
}

body {
  background-image: url("./assets/Images/background/background.png");
  background-size: 100%;
}

h1 {
  /*font-size: 72pt;*/
  font-size: 10vw;
}

h2 {
  font-size: 8vw;
}

h3 {
  font-size: 3vw;
  margin-bottom: 10px;
}

p {
  font-size: 1.4vw;
  margin-top: 0px;
}

@media only screen and (max-width: 700px) {
  h1 {
    font-size: 10vw;
  }

  h2 {
    font-size: 9vw;
  }

  h3 {
    font-size: 6vw;
  }

  p {
    font-size: 3vw;
  }
}
