* {
  box-sizing: border-box;
}
.Avatare {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  color: white;
}

.Avatare :global h2 {
  text-align: center;
}

.Avatare :global .avatar {
  width: 100%;
}

.Avatare :global .content {
  position: relative;
}

.Avatare :global .overlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  padding: 5px;
}

.Avatare :global p {
  font-size: 1.2vw;
  line-height: 1.2;
}

.Avatare :global .email {
  font-size: 0.8vw;
  overflow: visible;
}

.Avatare :global .content:hover .overlay {
  transition: 0.5s ease;
  opacity: 1;
}

.Avatare :global .content:hover .avatar {
  transition: 0.5s ease;
  opacity: 0.5;
}

.Avatare :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Avatare :global [class*="col-"] {
  float: left;
  padding: 15px;
  /*border: 0px solid #5a1bc0;*/
}

.Avatare :global .col-1 {
  width: 8.33%;
}
.Avatare :global .col-2 {
  width: 16.66%;
}
.Avatare :global .col-3 {
  width: 25%;
}
.Avatare :global .col-4 {
  width: 33.33%;
}
.Avatare :global .col-5 {
  width: 41.66%;
}
.Avatare :global .col-6 {
  width: 50%;
}
.Avatare :global .col-7 {
  width: 58.33%;
}
.Avatare :global .col-8 {
  width: 66.66%;
}
.Avatare :global .col-9 {
  width: 75%;
}
.Avatare :global .col-10 {
  width: 83.33%;
}
.Avatare :global .col-11 {
  width: 91.66%;
}
.Avatare :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .Avatare :global [class*="col-2"] {
    width: 33.33%;
  }

  .Avatare :global .email {
    visibility: hidden;
  }

  .Avatare :global .resize {
    line-height: 1;
    margin-top: 60%;
  }
}
