/*body {
  background-image: url("../../assets/Images/background/background.png");
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100%;
}*/

.Header {
  /*background-color: darkblue;*/
  color: white;
  text-align: center;
  padding-top: 3%;
  padding-bottom: 15%;
  /*padding-bottom: 60%;*/
  /*position: relative;*/
}

.Header :global h1 {
  color: rgb(255, 255, 255);
  text-shadow: -0.5vw 0.2vw #85208f;
  /*font-size: 10vw;*/

  /*position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;*/
}

/*.Header :global .background1 {
  width: 100%;
}*/
