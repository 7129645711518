* {
  box-sizing: border-box;
}

.Imd {
  /*margin-bottom: 50%;*/
  /*position: relative;*/

  /*z-index: 3;*/
}
.Imd :global .front {
  /*position: absolute;*/
  top: 0%;
  left: 0%;
}

.Imd :global .planet {
  width: 200%;
  height: auto;
  max-height: 1000px;
  max-width: 1000px;
  margin-left: -30%;
  margin-top: -10%;
  float: right;
}

.Imd :global .content {
  text-align: left;
  float: right;
  color: rgb(255, 255, 255);
  width: 80%;
  margin-right: 10%;
}
.Imd :global h3 {
  margin-bottom: 10px;
}

.Imd :global p {
  margin-top: 0%;
}

.Imd :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Imd :global [class*="col-"] {
  float: left;
  padding: 15px;
}

.Imd :global .col-1 {
  width: 8.33%;
}
.Imd :global .col-2 {
  width: 16.66%;
}
.Imd :global .col-3 {
  width: 25%;
}
.Imd :global .col-4 {
  width: 33.33%;
}
.Imd :global .col-5 {
  width: 41.66%;
}
.Imd :global .col-6 {
  width: 50%;
}
.Imd :global .col-7 {
  width: 58.33%;
}
.Imd :global .col-8 {
  width: 66.66%;
}
.Imd :global .col-9 {
  width: 75%;
}
.Imd :global .col-10 {
  width: 83.33%;
}
.Imd :global .col-11 {
  width: 91.66%;
}
.Imd :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .Imd :global [class*="col-"] {
    width: 100%;
  }
  .Imd :global .planet {
    width: 70%;
    margin-left: -20%;
    margin-top: -10%;
    float: left;
  }
}
