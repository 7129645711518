* {
  box-sizing: border-box;
}

.Semester {
  color: white;
}
.Semester :global h2 {
  text-align: center;
}
.Semester :global h3 {
  margin-bottom: 10px;
}
.Semester :global p {
  margin-top: 0px;
}

.Semester :global .zweitie {
  width: 80%;
  margin-top: 30%;
}

.Semester :global .viertie {
  width: 120%;
  margin-top: -10%;
  float: right;
}

.Semester :global .sechstie {
  width: 200%;
  margin-top: -15%;
}

.Semester :global .astronaut {
  width: 80%;
  float: right;
  margin-top: -30%;
}

.Semester :global .content-right {
  text-align: left;
  float: right;
  width: 80%;
  margin-right: 10%;
}
.Semester :global .content-left {
  text-align: left;
  float: left;
  width: 80%;
  margin-left: 10%;
}

.Semester :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Semester :global [class*="col-"] {
  float: left;
  padding: 15px;
  /*border: 3px solid #73ad21;*/
}
.Semester :global .container {
  overflow: hidden;
  padding: 0px;
  /*border: 3px solid #73ad21;*/
}

.Semester :global .col-1 {
  width: 8.33%;
}
.Semester :global .col-2 {
  width: 16.66%;
}
.Semester :global .col-3 {
  width: 25%;
}
.Semester :global .col-4 {
  width: 33.33%;
}
.Semester :global .col-5 {
  width: 41.66%;
}
.Semester :global .col-6 {
  width: 50%;
}
.Semester :global .col-7 {
  width: 58.33%;
}
.Semester :global .col-8 {
  width: 66.66%;
}
.Semester :global .col-9 {
  width: 75%;
}
.Semester :global .col-10 {
  width: 83.33%;
}
.Semester :global .col-11 {
  width: 91.66%;
}
.Semester :global .col-12 {
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .Semester :global .zweitie {
    margin-top: 5%;
    float: right;
  }

  .Semester :global .viertie {
    width: 120%;
    margin-top: -10%;
    float: right;
  }

  .Semester :global .sechstie {
    width: 150%;
    margin-top: -10%;
  }
}
@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .Semester :global [class*="col-"] {
    width: 100%;
  }
  .Semester :global .planet-right {
    transform: translate(-25%, 0%);
  }
  .Semester :global .planet-left {
    transform: translate(25%, 0%);
  }
}

@media only screen and (max-width: 1260px) {
  .Semester :global .astronaut {
    float: left;
    margin-top: 0%;
  }
}
