* {
  box-sizing: border-box;
}
.Electives {
  position: relative;
  color: white;
}

.Electives :global h2 {
  text-align: center;
}

.Electives :global .stars {
  width: 100%;
  margin-top: -20%;
  margin-bottom: -20%;
}

.Electives :global .container {
  overflow: hidden;
}

.Electives :global .astronaut {
  width: 70%;
  float: left;
  margin-left: 20%;
  margin-top: 40%;
}

.Electives :global .planet {
  width: 110%;
  float: right;
  margin-top: -30%;
  margin-right: -25%;
}

.Electives :global .left {
  margin-top: 24%;
  padding-left: 3% !important;
  padding-right: 3% !important;
}
.Electives :global .center {
  margin-top: 12%;
  padding-left: 3% !important;
  padding-right: 3% !important;
}
.Electives :global .right {
  margin-top: 0%;
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.Electives :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Electives :global [class*="col-"] {
  float: left;
}

.Electives :global .col-1 {
  width: 8.33%;
}
.Electives :global .col-2 {
  width: 16.66%;
}
.Electives :global .col-3 {
  width: 25%;
}
.Electives :global .col-4 {
  width: 33.33%;
}
.Electives :global .col-5 {
  width: 41.66%;
}
.Electives :global .col-6 {
  width: 50%;
}
.Electives :global .col-7 {
  width: 58.33%;
}
.Electives :global .col-8 {
  width: 66.66%;
}
.Electives :global .col-9 {
  width: 75%;
}
.Electives :global .col-10 {
  width: 83.33%;
}
.Electives :global .col-11 {
  width: 91.66%;
}
.Electives :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  /* For mobile phones: */

  .Electives :global [class*="col-"] {
    width: 100%;
  }

  .Electives :global .stars {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .Electives :global .astronaut {
    margin-top: 10%;
  }

  .Electives :global .planet {
    width: 100%;
    margin-top: 0%;
  }
  .Electives :global .left {
    margin-top: 0%;
  }
  .Electives :global .center {
    margin-top: 0%;
  }
  .Electives :global .right {
    margin-top: 0%;
  }
}
