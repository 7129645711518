* {
  box-sizing: border-box;
}

.Programm {
  margin-left: 10%;
  margin-right: 10%;
  position: relative;
}

.Programm :global h2 {
  text-align: center;
  color: #ffffff;
}
.Programm :global h3 {
  text-align: left;
}
.Programm :global .timetable {
  line-height: 180%;
}

.Programm :global .split {
  display: block;
}

.Programm :global .split span {
  display: block;
  float: right;
  font-weight: bold;
}

.Programm :global .dayOne {
  color: #ffffff;
  text-align: left;
  float: left;
  margin-right: 2%;
}
.Programm :global .dayTwo {
  color: #ffffff;
  text-align: left;
  float: right;
  margin-top: 200px;
  margin-left: 2%;
}

.Programm :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Programm :global [class*="col-"] {
  padding: 15px;
  float: left;
}

.Programm :global .col-1 {
  width: 8.33%;
}
.Programm :global .col-2 {
  width: 16.66%;
}
.Programm :global .col-3 {
  width: 25%;
}
.Programm :global .col-4 {
  width: 33.33%;
}
.Programm :global .col-5 {
  width: 41.66%;
}
.Programm :global .col-6 {
  width: 50%;
}
.Programm :global .col-7 {
  width: 58.33%;
}
.Programm :global .col-8 {
  width: 66.66%;
}
.Programm :global .col-9 {
  width: 75%;
}
.Programm :global .col-10 {
  width: 83.33%;
}
.Programm :global .col-11 {
  width: 91.66%;
}
.Programm :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .Programm :global [class*="col-"] {
    width: 100%;
  }

  .Programm :global .dayOne {
    width: 83.33%;
    margin-left: 8.33%;
    margin-right: 8.33%;
    margin-bottom: 2%;
  }
  .Programm :global .dayTwo {
    width: 83.33%;
    margin-left: 8.33%;
    margin-right: 8.33%;
    margin-top: 2%;
  }
}
