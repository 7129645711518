* {
  box-sizing: border-box;
}

.Impressum :global h1 {
  text-align: center;
  color: white;
}

.Impressum :global .content {
  margin-left: 8.33%;
  margin-right: 8.33%;
  color: white;
}
