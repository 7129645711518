* {
  box-sizing: border-box;
}

.Trailer {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-basis: 100%;
}

.Trailer :global h2 {
  text-align: center;
  color: white;
}

.Trailer :global .video {
  height: 900px;
}

.Trailer :global .TrailerContainer {
  margin: 0 auto;
  width: 0px;
}
.Trailer :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Trailer :global [class*="col-"] {
  float: left;
  padding: 15px;
}

.Trailer :global .col-1 {
  width: 8.33%;
}
.Trailer :global .col-2 {
  width: 16.66%;
}
.Trailer :global .col-3 {
  width: 25%;
}
.Trailer :global .col-4 {
  width: 33.33%;
}
.Trailer :global .col-5 {
  width: 41.66%;
}
.Trailer :global .col-6 {
  width: 50%;
}
.Trailer :global .col-7 {
  width: 58.33%;
}
.Trailer :global .col-8 {
  width: 66.66%;
}
.Trailer :global .col-9 {
  width: 75%;
}
.Trailer :global .col-10 {
  width: 83.33%;
}
.Trailer :global .col-11 {
  width: 91.66%;
}
.Trailer :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .Trailer :global [class*="col-"] {
    width: 100%;
  }
}
