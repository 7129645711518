* {
  box-sizing: border-box;
}

.Footer {
  margin-left: 0%;
  margin-right: 0%;
  position: relative;
}

.Footer :global .button {
  font-size: 2vw;
  float: right;
  margin-left: 2%;
  margin-right: 2%;
  color: white;
  background-color: rgb(255, 255, 255, 0);
  border-width: 0px;
}

.Footer :global .home {
  font-size: 2vw;
  float: left;
  margin-left: 2%;
  margin-right: 2%;
  color: white;
  background-color: rgb(255, 255, 255, 0);
  border-width: 0px;
}

.Footer :global .row::after {
  content: "";
  clear: both;
  display: block;
}

.Footer :global [class*="col-"] {
  float: left;
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 2%;
  padding-right: 2%;
  /*border: 3px solid #73ad21;*/
}

.Footer :global .col-1 {
  width: 8.33%;
}
.Footer :global .col-2 {
  width: 16.66%;
}
.Footer :global .col-3 {
  width: 25%;
}
.Footer :global .col-4 {
  width: 33.33%;
}
.Footer :global .col-5 {
  width: 41.66%;
}
.Footer :global .col-6 {
  width: 50%;
}
.Footer :global .col-7 {
  width: 58.33%;
}
.Footer :global .col-8 {
  width: 66.66%;
}
.Footer :global .col-9 {
  width: 75%;
}
.Footer :global .col-10 {
  width: 83.33%;
}
.Footer :global .col-11 {
  width: 91.66%;
}
.Footer :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 678px) {
  /* For mobile phones: */

  .Footer :global [class*="col-"] {
    width: 100%;
  }
}
