* {
  box-sizing: border-box;
}
.Datenschutz :global h1 {
  text-align: center;
  color: white;
  font-size: 10vw;
}

.Datenschutz :global .content {
  padding-left: 4.165%;
  padding-right: 4.165%;
  color: white;
}

.Datenschutz .row::after {
  content: "";
  clear: both;
  display: block;
}

.Datenschutz :global .col-1 {
  width: 8.33%;
}
.Datenschutz :global .col-2 {
  width: 16.66%;
}
.Datenschutz :global .col-3 {
  width: 25%;
}
.Datenschutz :global .col-4 {
  width: 33.33%;
}
.Datenschutz :global .col-5 {
  width: 41.66%;
}
.Datenschutz :global .col-6 {
  width: 50%;
}
.Datenschutz :global .col-7 {
  width: 58.33%;
}
.Datenschutz :global .col-8 {
  width: 66.66%;
}
.Datenschutz :global .col-9 {
  width: 75%;
}
.Datenschutz :global .col-10 {
  width: 83.33%;
}
.Datenschutz :global .col-11 {
  width: 91.66%;
}
.Datenschutz :global .col-12 {
  width: 100%;
}

@media only screen and (max-width: 700px) {
  /* For mobile phones: */

  .Datenschutz :global [class*="col-"] {
    width: 100%;
  }
}
